"use client";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Grid,
} from "@mui/material";
import { BlackTooltip } from "helpers/helpers";

interface MainDialogProps {
  open?: any;
  handleClose?: any;
  handleRequest?: any;
  handleApprove?: any;
  message?: any;
  closeText?: string;
  approveText?: any;
  maxWidth?: any;
  icon?: any;
  title?: any;
  color?: any;
  approveTextDisabled?: any;
  approveTextTip?: any;
}

export default function MainDialog({
  open,
  handleClose,
  handleRequest,
  message,
  closeText,
  approveText,
  handleApprove,
  maxWidth,
  icon,
  title,
  color,
  approveTextDisabled,
  approveTextTip,
}: MainDialogProps) {
  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "sm"}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ pl: "40px", pr: "40px", pt: 4, pb: 2 }}>
        <Grid container>
          <Grid item xs={12} md={2} display={{ xs: icon ? "block" : "none" }}>
            {icon ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                {icon}
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} md={icon ? 10 : 12}>
            <Box
              sx={{
                fontWeight: "bold",
                color: color ? color : "#000",
                fontSize: "1.1rem",
                textAlign: "left",
              }}
            >
              {title}
            </Box>
            <Box
              sx={{
                fontWeight: "400",
                fontSize: "1rem",
                textAlign: "left",
              }}
            >
              {message}
            </Box>
          </Grid>
        </Grid>

      </DialogContent>
      {handleRequest || approveText || closeText ? (
        <DialogActions sx={{ padding: "10px", background: "rgba(0,0,0,.1)" }}>
          <>
            {handleRequest && (
              <Button
                className="tailwind-button"
                onClick={handleRequest}
                autoFocus
              >
                Request Access
              </Button>
            )}
            {approveText && (
              <BlackTooltip title={approveTextTip}>
                <Box mr={1} ml={1}>
                  <Button
                    className="tailwind-button"
                    onClick={handleApprove}
                    disabled={approveTextDisabled}
                  >
                    {approveText}
                  </Button>
                </Box>
              </BlackTooltip>
            )}
            {closeText && (
              <Button
                className="tailwind-button-red"
                onClick={handleClose}
                autoFocus
              >
                {closeText}
              </Button>
            )}
          </>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
