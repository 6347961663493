const poster = async (url: string, token: string | null, data: any) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  const newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;
  
  // If data contains a File or Blob, use FormData
  const containsFile = data.image instanceof File || data.image instanceof Blob;
  
  try {
    let formData;
    let requestBody;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    if (containsFile) {
      formData = new FormData();
      // Add the image file
      formData.append('image', data.image);
      
      // Add all other fields
      Object.keys(data).forEach(key => {
        if (key !== 'image') {
          formData.append(key, data[key]);
        }
      });
      
      requestBody = formData;
      // Don't set Content-Type for FormData
    } else {
      requestBody = JSON.stringify(data);
      headers['Content-Type'] = 'application/json';
    }
    
    if (formData) {
      console.log('FormData entries:');
      for (let pair of formData.entries()) {
        console.log(pair[0], typeof pair[1], pair[1] instanceof File);
      }
    }

    const res = await fetch(newUrl, {
      method: "POST",
      headers,
      body: requestBody,
    });

    // Log the raw response details
    console.log('Response status:', res.status);
    console.log('Response headers:', Object.fromEntries(res.headers.entries()));
    const responseText = await res.text();
    console.log('Response body:', responseText);

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status} - ${responseText || 'Empty response'}`);
    }

    // Handle empty response
    if (!responseText.trim()) {
      console.log('Received empty response from server');
      return null; // or return {} if you prefer
    }

    const contentType = res.headers.get("content-type");
    if (contentType?.includes("application/json")) {
      try {
        return JSON.parse(responseText);
      } catch (jsonError) {
        console.error("Failed to parse JSON response:", jsonError);
        throw new Error(`Invalid JSON response. Status: ${res.status}, Content-Type: ${contentType}, Body: ${responseText}`);
      }
    }
    return responseText;

  } catch (error) {
    console.error("Upload error:", error);
    throw error;
  }
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

const postAPI = async (url: string, data: any) => {
  try {
    const token = await fetchToken();
    return await poster(url, token, data);
  } catch (error) {
    if (error instanceof Error) {
      try {
        const parsedError = JSON.parse(error.message);
        console.error("API Error:", parsedError);
        // You can handle the error here or rethrow it
        throw new Error(
          `API Error: ${parsedError.status} ${
            parsedError.statusText
          }\n${JSON.stringify(parsedError.errorData)}`
        );
      } catch {
        // If parsing fails, it's not our custom error object
        console.error("Error in API call:", error.message);
        throw error;
      }
    } else {
      console.error("Unknown error in API call:", error);
      throw new Error("Unknown error in API call");
    }
  }
};

export default postAPI;
