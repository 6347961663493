import { useReducer, useEffect, useState } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/router";

const fetcher = async (url: string, token: string | null) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  let newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  const res = await fetch(newUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    throw new Error("Failed to fetch data");
  }

  const data = await res.json();
  return data;
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    localStorage.setItem("token", data.accessToken); // Store the token in localStorage
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

// Define the reducer
const tokenReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return action.token;
    default:
      return state;
  }
};

const isTokenExpired = (token) => {
  try {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp * 1000; // Convert to milliseconds
    return Date.now() > exp;
  } catch (error) {
    console.error("Failed to decode token", error);
    return true; // Assume expired if there's an error decoding
  }
};

const defaultTransformer = (data: any) => data?.result || data;

interface UseApiOptions {
  transformer?: (data: any) => any;
  immutable?: boolean;
}

const useApi = (
  url: string,
  condition?: boolean,
  options: UseApiOptions = {}
) => {
  const router = useRouter();
  const [token, dispatch] = useReducer(tokenReducer, null);
  const [apiError, setApiError] = useState<Error | null>(null);
  const { immutable = false, transformer = defaultTransformer } = options;

  useEffect(() => {
    const getToken = async () => {
      try {
        let accessToken = localStorage.getItem("token");
        if (accessToken && isTokenExpired(accessToken)) {
          accessToken = null; // Clear the expired token
        }
        if (!accessToken) {
          accessToken = await fetchToken(); // Fetch a new token if it's not in localStorage or if expired
          localStorage.setItem("token", accessToken ? accessToken : ""); // Store the new token
        }
        if (isTokenExpired(accessToken)) {
          localStorage.removeItem("token");
          accessToken = null;
          router.push("/api/auth/login");
        }

        if (!accessToken) {
          throw new Error("Token is not available");
        }

        dispatch({ type: "SET_TOKEN", token: accessToken });
      } catch (error) {
        console.error(error);
        setApiError(error as Error);
      }
    };

    getToken();
  }, [url]);

  const swrHook = immutable ? useSWRImmutable : useSWR;

  const {
    data: uncleanedData,
    error,
    mutate,
    isLoading,
  } = swrHook(condition && token && url ? url : null, (url) =>
    fetcher(url, token)
  );
  const data = transformer(uncleanedData);
  return { data, isLoading, mutate, error, apiError };
};

export default useApi;
